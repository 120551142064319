<template lang="html">
  <SlideOut
    append-to=".videoapp"
    panel="#panel"
    dock="right"
    :visible.sync="visible"
    size="100%"
    mask-color="rgba(0,0,0,0)"
    :title="article.title"
    custom-class="article-slideout"
    @close="onClose"
    :ratio="2"
  >
    <vue-custom-scrollbar id="slideOut__container">
      <div class="body__title">
        <div class="uk-background-muted uk-padding body">
          <h1
            v-if="article.title"
            v-html="article.title"
            class="body__title"
            id="element"
          ></h1>
          <h3
            v-if="article.subtitle"
            v-html="article.subtitle"
            class="body__subtitle"
          ></h3>
          <div v-if="article.body" class="body_text">
            <vue-markdown>
              {{ article.body }}
            </vue-markdown>
          </div>
        </div>
      </div>

      <Article
        class="uk-background-default articles"
        v-for="(slide, index) in article.slides"
        :key="index"
        :slide="slide"
      ></Article>
      <div class="uk-background-default uk-padding uk-margin-medium-bottom">
        <div
          class="uk-child-width-expand uk-margin-remove-top uk-margin-remove-left "
          uk-grid
        ></div>
      </div>
    </vue-custom-scrollbar>
  </SlideOut>
</template>

<script>
import SlideOut from "@hyjiacan/vue-slideout";
import "@hyjiacan/vue-slideout/lib/slideout.css";
import vueCustomScrollbar from "vue-custom-scrollbar";
import { bus } from "../../main";
import Article from "./Article.vue";
export default {
  data: function() {
    return {
      visible: false,
      strings: this._vaData.strings,
      article: Object
    };
  },
  props: {},
  components: {
    SlideOut,
    Article,
    // VueScrollTo,
    vueCustomScrollbar
  },
  methods: {
    onClose() {
      var myDiv1 = document.getElementById("slideOut__container");
      myDiv1.scrollTop = 0;
    }
  },
  mounted() {
    bus.$on("show_slideout", data => {
      if (data) {
        this.article = data;
        this.visible = true;
      } else {
        // mach das bestehende slideout auf
      }
      // this.visible = true;
    });
    bus.$on("hide_slideout", () => {
      this.article = {};
      this.visible = false;
    });
  }
};
</script>

<style lang="scss" scoped="">
.body__title {
  margin-bottom: 20px;
}
.body__subtitle {
  color: $global-tertiary-color;
  display: block;
  font-size: 1.5em;
  line-height: 1.2;
  margin-top: 0;
}
ul {
  margin-bottom: 10px;
}
.articles {
  padding: 30px;
}
.vue-slideout-header {
  padding: 5px 30px;
}
.body_text {
  margin: 0;
  margin-bottom: 0 !important;
  p {
    margin: 0;
    margin-bottom: 0 !important;
  }
}
</style>

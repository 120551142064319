<template lang="html">
  <header class="header">
    <a
      class="logo"
      :title="strings.app_title"
      v-if="settings.logo"
      @click="goHome"
    >
      <img :src="_vaData.apiBase + settings.logo" :alt="strings.app_title" />
    </a>
    <Title class="title" />

    <button class="menu-btn" uk-toggle="target: #offcanvas">
      <div></div>
      <div></div>
      <div></div>
    </button>
  </header>
</template>

<script>
import Title from "./Title.vue";
import { bus } from "../main";

export default {
  data: function() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings
    };
  },
  methods: {
    goHome() {
      bus.$emit("go_to_location", this.settings.start_location);
    }
  },
  components: {
    Title
  }
};
</script>

<style lang="scss" scoped>
body {
  &.is-mobile {
    .app_title {
      display: none;
      overflow: hidden;
    }
    .menu-btn {
      height: 25px;
      width: 25px;
      & > div {
        height: 0.07em;
        width: 90%;
        background: #000;
        transition: background-color 0.2s;
      }
    }
    .header {
      padding: 15px;
      justify-content: space-between;
      position: static;
    }
    .header_title {
      display: none;
    }
    .logo {
      min-width: 80px;
      max-width: 80px;
    }
    .title {
      display: none;
    }
  }
  &.is-tablet,
  &.is-tablet-ios,
  &.is-android {
    @media (orientation: landscape) {
      .header {
        padding: 15px;
        justify-content: space-between;
        position: static;
      }
      .logo {
        min-width: 80px;
        max-width: 80px;
      }
    }
    @media (orientation: portrait) {
      .app_title {
        display: none;
        overflow: hidden;
      }
      .header {
        padding: 15px;
        justify-content: space-between;
        position: static;
      }
      .header_title {
        display: none;
      }
      .logo {
        min-width: 80px;
        max-width: 80px;
      }
      .title {
        display: none;
      }
    }
  }
  &.is-tablet,
  &.is-android {
    .menu-btn {
      height: 25px;
      width: 25px;
      & > div {
        height: 0.07em;
        width: 90%;
        background: #000;
        transition: background-color 0.2s;
      }
    }
  }
  &.is-tablet-ios {
    .menu-btn {
      height: 25px;
      width: 25px;
      & > div {
        height: 1px;
        width: 25px;
        background: #000;
        transition: background-color 0.2s;
      }
    }
  }
}
.header {
  overflow: hidden;
  z-index: 2002;
  font-size: 14px;
  padding: 20px 30px;
  display: flex;
  flex-shrink: 0;
  align-items: flex-end;
  gap: 2vw;
  position: static;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(255, 255, 255);

  a,
  button {
    pointer-events: all;
  }
}

.logo {
  pointer-events: all;
  min-width: 120px;
  max-width: 140px;

  img {
    width: 100%;
    display: block;
  }
}

.menu-btn {
  height: 36px;
  width: 36px;
  flex-shrink: 0;
  background: transparent;
  border: none;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;
  position: relative;
  cursor: pointer;
  transition: background-color 0.2s;
  & > div {
    height: 0.15em;
    width: 90%;
    background: #000;
    transition: background-color 0.2s;
  }

  &:hover {
    & > div {
      background: $global-emphasis-color;
    }
  }

  .uk-offcanvas-container & {
    & > div {
      transform: rotate(45deg);
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      // z-index: ;
      &:nth-child(1) {
        transform: rotate(45deg);
      }
      &:nth-child(2) {
        transform: rotate(-45deg);
      }
      &:nth-child(3) {
        display: none;
      }
    }
  }
}
</style>

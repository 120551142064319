<template>
  <div class="uk-position-relative slide">
    <div v-if="slide.title || slide.subtitle" class="header">
      <h3 class="slide__title" v-if="slide.title">{{ slide.title }}</h3>
      <span class="slide__subtitle" v-if="slide.subtitle">{{
        slide.subtitle
      }}</span>
    </div>
    <div class="slide__media">
      <div v-if="!slide.src" class="no_media">
        <!-- Fallback -->
        <!-- <img src="fallback.jpg" alt="Asset not found" /> -->
      </div>

      <template v-else>
        <div v-if="slide.type === 'video'" class="media">
          <video
            class="uk-width-1-1"
            controls
            :poster="_vaData.apiBase + slide.poster"
            :src="_vaData.apiBase + slide.src"
          ></video>
        </div>

        <!-- <div v-if="slide.type === 'image'" class="media">
          <img :src="_vaData.apiBase + slide.src" :alt="slide.title" />
        </div> -->

        <div
          v-if="slide.type === 'image'"
          class="media"
          uk-lightbox
          style="z-index:2000;"
        >
          <a
            class="lightbox-link"
            :href="_vaData.apiBase + slide.src"
            :data-caption="slide.title"
          >
            <img :src="_vaData.apiBase + slide.src" :alt="slide.title" />
            <button class="ctl">
              <span uk-icon="icon: expand"></span>
            </button>
          </a>
        </div>

        <div v-if="slide.type === 'iframe'" class="media">
          <div class=" media_iframe">
            <iframe
              :src="
                slide.src +
                  (slide.src.includes('youtube') ? '?enablejsapi=1' : '')
              "
              allowfullscreen
            ></iframe>
          </div>
        </div>

        <div v-if="slide.caption" class="caption">
          <span>{{ slide.caption }}</span>
        </div>
      </template>
    </div>
    <div v-if="slide.body" class="body">
      <base target="_blank" />
      <vue-markdown>{{ slide.body }}</vue-markdown>
    </div>
  </div>
</template>

<script>
export default {
  name: "Article",
  components: {},
  mounted() {
    // const isSmall = window.innerWidth < 960;
  },
  data() {
    return {
      strings: this._vaData.strings
    };
  },
  props: {
    slide: Object
  },
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.header {
  @media (min-width: 960px) {
    height: 60px;
  }
}
.slide__title {
  margin-bottom: 0;
  color: $global-tertiary-color;
}

.slide__subtitle {
  text-transform: uppercase;
}

.slide__media {
  position: relative;
}
.body {
  padding-top: 0px;
}

.media {
  position: relative;
  text-align: center;
  // aspect-ratio: 16 / 9;
  display: block;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  &:before {
    float: left;
    padding-top: 56.25%;
    content: "";
  }
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
.no_media {
  display: none;
}

.caption {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 0.5em 1em;
  font-size: 11px;
  line-height: 1.5;
  // color: #fff;
  span {
    position: relative;
    z-index: 1;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    right: 0;
    background-color: #fff;
    opacity: 0.5;
  }
}

.media_iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
}

.button {
  margin-left: auto;
  margin-right: auto;
  padding: 0.25em 0.6em;
  user-select: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 14px;
  white-space: normal;
  min-width: 135px;
  max-width: 200px;
  height: 28px;
  position: relative;
  background-color: $global-emphasis-color;
  border: 2px solid $global-emphasis-color;
  color: #fff;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
  // box-shadow: rgba(0, 0, 0, 0.2) 0 3px 4px, rgba(0, 0, 0, 0.4) 0 4px 5px;
  @media (max-width: 1600px) {
  }
  &:hover {
    background: $base-body-color;
    color: #fff;
    border: 2px solid $base-body-color;
  }
  span {
    max-width: 100%;
    max-height: 100%;
    text-align: center;
  }
}

.uk-lightbox {
  z-index: 2001 !important;
  .uk-open {
    z-index: 2000 !important;
  }
}

.uk-lightbox .uk-open {
  z-index: 2000 !important;
}
.lightbox-link {
  display: inline-block;
  width: 100%;
  .ctl {
    position: absolute;
    z-index: 1;
    bottom: 15px;
    right: 15px;
    height: 46px;
    width: 46px;

    cursor: pointer;
    background-color: $global-emphasis-color;
    color: #fff;
    text-align: center;
    border: none;

    &:hover {
      filter: brightness(110%);
    }
  }
}
</style>

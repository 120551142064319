<template lang="html">
  <!-- <div v-if="locales.length > 1" class="language-switcher">
    <template v-if="locales.length === 2">
      <a
        class="trigger uk-button uk-button-default uk-button-small"
        :href="'?locale=' + notCurrentLocale.code"
      >
        <lang-flag :iso="notCurrentLocale.code" /> {{ notCurrentLocale.name }}
      </a>
    </template>
    <template v-else>
      <button class="trigger uk-button uk-button-small">
        <lang-flag :iso="currentLocale.code" /> {{ currentLocale.name }}
      </button>
      <div uk-dropdown="pos: top-right; mode: click;">
        <ul class="uk-nav uk-dropdown-nav">
          <li
            v-for="locale in locales"
            :key="locale.id"
            :class="locale.code === currentLocale.code ? 'current' : ''"
          >
            <a :href="'?locale=' + locale.code"
              ><lang-flag :iso="locale.code" /> {{ locale.name }}</a
            >
          </li>
        </ul>
      </div>
    </template>
  </div> -->

  <div class="language-switcher">
    <div class="uk-grid-small uk-grid-divider" uk-grid>
      <div>
        <a
          href="/?locale=de"
          data-lang="de"
          :class="
            this._vaData.currentLocale.code === 'de' ? 'current' : 'not-current'
          "
          ><i class="icon -language-de"></i
        ></a>
      </div>
      <div>
        <a
          href="/?locale=en"
          data-lang="en"
          :class="
            this._vaData.currentLocale.code === 'en' ? 'current' : 'not-current'
          "
          ><i class="icon -language-en"></i
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import LangFlag from "vue-lang-code-flags";
export default {
  components: {
    // eslint-disable-next-line
    LangFlag
  },
  computed: {
    locales() {
      return this._vaData.availableLocales;
    },
    currentLocale() {
      return this._vaData.currentLocale;
    },
    notCurrentLocale() {
      return this._vaData.availableLocales.find(
        l => this.currentLocale.id !== l.id
      );
    }
  },
  mounted() {
    // this.$store.dispatch('data/setCurrentLocale', this.currentLocale)
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.flag-icon {
  position: relative;
  top: -0.05em;
  margin-right: 0.5em;
}

.uk-button {
  background: #dd0060;
  color: #fff;

  &:hover {
    background-color: darken(#dd0060, 5);
    color: #fff;
  }
}

.language-switcher {
  .current {
    text-decoration: underline;
  }

  a {
    color: #000;
    &:hover {
      color: #fffs;
    }
  }
}
</style>

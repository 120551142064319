<template>
  <div class="videothing">
    <!-- here -->
  </div>
</template>

<script>
import VideoThing from "videoThing";
import "../../../node_modules/videoThing/dist/videoThing.css";
import { bus } from "../../main";

export default {
  name: "VideoThing",
  props: {
    settings: Object
  },
  created() {
    bus.$on("go_back", () => {
      this.videoThing.goBack();
    });
    bus.$on("show_hotspots", () => {
      this.videoThing.showHotspots();
    });
    bus.$on("hide_hotspots", () => {
      this.videoThing.hideHotspots();
    });
    bus.$on("show_navigation", () => {
      this.videoThing.showNavigation();
    });
    bus.$on("hide_navigation", () => {
      this.videoThing.hideNavigation();
    });
    bus.$on("show_menus", () => {
      this.videoThing.showMenus();
    });
    bus.$on("hide_menus", () => {
      this.videoThing.hideMenus();
    });
    bus.$on("go_to_location", id => {
      this.videoThing.goToLocation(id);
    });
  },
  methods: {},
  mounted() {
    const elem = document.querySelector(".videothing");
    this.videoThing = new VideoThing(
      elem,
      this._vaData.locations,
      this._vaData.destinations,
      this.settings
    );

    this.videoThing.on("location_reached", id => {
      bus.$emit("location_reached", id, this.videoThing.history);

      this.$nextTick(() => {
        // if last location is new location, remove it from history
        const history = this.videoThing.history;
        if (
          history.length > 1 &&
          history[history.length - 1] === history[history.length - 2]
        ) {
          this.videoThing.history.pop();
        }
      });
    });

    this.videoThing.on("location_left", id => {
      bus.$emit("location_left", id, this.videoThing.history);
    });

    this.videoThing.on("custom_button_clicked", (e, item) => {
      bus.$emit("show_content", item.target_product.id, "products", "slideout");
    });
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
body {
  &.is-mobile {
    .videothing {
      .controls {
        .button {
          background: #37a58c;
          &[class*="btn-square"] {
            height: 20px !important;
            width: 20px !important;
            min-width: 20px;
            background-color: #7f7f7f !important;
          }
          &.btn-square--left {
            display: block !important;
            background-image: url("../../assets/chevron-left.svg");
            background-color: #7f7f7f !important;
            background-repeat: no-repeat;
            background-size: 100%;

            &:hover {
              background-color: #000;
            }
          }
          &.btn-square--right {
            display: block !important;
            background-image: url("../../assets/chevron-right.svg");
            background-color: #7f7f7f !important;
            background-repeat: no-repeat;
            background-size: 100%;
            &:hover {
              background-color: #000;
            }
          }
          &[data-type="hotspot"] {
            display: none;
          }

          &[data-type="navigation"] {
            display: none;
          }
        }
        &:hover {
          background-position: unset;
        }
      }
    }
  }
  &.is-tablet,
  &.is-tablet-ios,
  &.is-android {
    @media (orientation: portrait) {
      .videothing {
        .controls {
          .button {
            background: #37a58c;
            &[data-type="hotspot"] {
              display: none;
            }

            &[data-type="navigation"] {
              display: none;
            }
            &[class*="btn-square"] {
              display: block !important;
              height: 25px !important;
              width: 25px !important;
              min-width: 25px;
              background-color: #7f7f7f !important;
            }
            &.btn-square--left {
              display: block !important;
              background-image: url("../../assets/chevron-left.svg");
              background-color: #7f7f7f !important;
              background-repeat: no-repeat;
              background-size: 100%;

              &:hover {
                background-color: #000;
              }
            }
            &.btn-square--right {
              display: block !important;
              background-image: url("../../assets/chevron-right.svg");
              background-color: #7f7f7f !important;
              background-repeat: no-repeat;
              background-size: 100%;
              &:hover {
                background-color: #000;
              }
            }
            &:hover {
              background-position: none;
            }
          }
        }
      }
    }
  }
  &.is-tablet,
  &.is-tablet-ios,
  &.is-android {
    @media (orientation: landscape) {
      .videothing {
        .controls {
          .button {
            background: #37a58c;
            background-color: #37a58c !important;
            &[data-type="navigation"] {
              &:hover {
                background-color: #37a58c !important;
              }
              &:active {
                background-color: #37a58c !important;
              }
            }
            &[class*="btn-square"] {
              display: block !important;
              height: 30px !important;
              width: 30px !important;
              min-width: 30px;
              background-color: #7f7f7f !important;
            }
            &.btn-square--left {
              background-image: url("../../assets/chevron-left.svg");
              background-color: #7f7f7f !important;
              background-repeat: no-repeat;
              background-size: 100%;
              &:hover {
                background-color: #7f7f7f !important;
                background-image: url("../../assets/chevron-left.svg");
              }
              &:active {
                background-color: #7f7f7f !important;
                background-image: url("../../assets/chevron-left.svg");
              }
            }
            &.btn-square--right {
              background-image: url("../../assets/chevron-right.svg");
              background-color: #7f7f7f !important;
              background-repeat: no-repeat;
              background-size: 100%;
              &:hover {
                background-color: #7f7f7f !important;
                background-image: url("../../assets/chevron-right.svg") !important;
              }
              &:active {
                background-color: #7f7f7f !important;
                background-image: url("../../assets/chevron-right.svg") !important;
              }
            }
            &.btn-square--content {
              background-image: url("../../assets/arrow_left--content.svg");
              background-color: #37a58c !important;
              background-repeat: no-repeat;
              background-size: 100%;
              &:hover {
                background-color: #37a58c !important;
                background-image: url("../../assets/arrow_left--content.svg") !important;
              }
              &:active {
                background-color: #37a58c !important;
                background-image: url("../../assets/arrow_left--content.svg") !important;
              }
            }
          }
        }
      }
    }
  }

  &.is-tablet-ios {
    @media (orientation: landscape) {
      .videothing {
        .controls {
          .button {
            -moz-appearance: none;
          }
        }
      }
    }
  }
}
.videothing {
  $hotspot-background: $global-emphasis-color;
  $navigation-background: $global-emphasis-color;

  flex-shrink: 0;
  flex-grow: 1;

  position: relative;
  @media (max-width: $breakpoint-large) {
    height: auto !important;

    // aspect-ratio: 2 / 1;
    // @supports not (aspect-ratio: 2 / 1) {
    &:before {
      float: left;
      padding-top: 50%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
  @media (max-width: $breakpoint-medium) {
    // aspect-ratio: 16 / 9;
    // @supports not (aspect-ratio: 16 /9) {
    &:before {
      float: left;
      padding-top: 56.25%;
      content: "";
    }
    &:after {
      display: block;
      content: "";
      clear: both;
    }
    // }
  }

  .controls--viewport_relative {
    // da ist einfehler im videothing css.
    position: fixed !important;
  }

  .controls {
    .button {
      // height: 28px;
      padding: 0.25em 0.6em;
      user-select: none;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 14px;
      white-space: normal;
      min-width: 135px;
      max-width: 200px;
      height: 28px;
      position: relative;
      background-color: $hotspot-background;
      color: #fff;
      box-shadow: rgba(0, 0, 0, 0.2) 0 2px 4px, rgba(0, 0, 0, 0.4) 0 2px 4px;

      background: linear-gradient(to left, #37a58c 50%, #000 50%);
      background-size: 205% 100%;
      background-position: right bottom;
      transition: all 0.7s;
      @media (max-width: 1600px) {
        padding: 0.25em 0.4em;
      }

      &.button_nowrap {
        white-space: nowrap;
      }

      &:hover {
        background-position: left bottom;
      }

      &[class*="btn-square"] {
        height: 40px !important;
        width: 40px !important;
        min-width: 40px;
        box-sizing: border-box;
        border-radius: none;
        position: relative;

        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;

        .button__text {
          display: none;
        }
        // @media (max-width: $breakpoint-large) {
        //   height: 20px !important;
        //   width: 20px !important;
        //   min-width: 20px;
        //   background-color: #7f7f7f !important;
        // }
      }

      &.btn-square--left {
        background-image: url("../../assets/chevron-left.svg");
        background-color: #7f7f7f !important;
        &:hover {
          background-color: #000;
        }
        // @media (max-width: $breakpoint-large) {
        //   display: block !important;
        // }
      }
      &.btn-square--right {
        background-image: url("../../assets/chevron-right.svg");
        background-color: #7f7f7f !important;
        &:hover {
          background-color: #000;
        }
        // @media (max-width: $breakpoint-large) {
        //   display: block !important;
        // }
      }

      &.btn-square--content {
        background-image: url("../../assets/arrow_left--content.svg");
        background-size: 70%;
        background-color: $global-emphasis-color;
        &:hover {
          background-color: #000;
        }
      }

      &[data-type="hotspot"] {
        // @media (max-width: $breakpoint-large) {
        //   display: none;
        // }
      }

      &[data-type="navigation"] {
        // @media (max-width: $breakpoint-large) {
        //
        //   display: none;
        // }
      }

      &.rehau_active_red {
        background-color: #dd0060;
        &:hover {
          background: $base-body-color;
          color: #fff;
        }
      }

      span {
        max-width: 100%;
        max-height: 100%;
        text-align: center;
      }

      // @media (max-width: $breakpoint-large) {
      //   // display: none
      //   min-width: auto;
      //   width: 20px;
      //   height: 20px;
      //   display: block;
      //   padding: 0;
      //   background-size: contain;
      //   span {
      //     display: none;
      //   }
      // }
    }
  }
}
</style>

<template lang="html">
  <div class="title">
    <div class="breadcrumb">
      <span>
        <a class="title__location" @click="goHome">{{ stringBreadcrumb }}</a>
      </span>
      <span
        v-if="parentLocation && parentSlug === '01'"
        class="breadcrumb_text"
      >
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2 class="title__location rehau-active-red">
          {{ locationTitle }}
        </h2>
      </span>
      <span
        v-if="parentLocation && parentSlug !== '01'"
        class="breadcrumb_text"
      >
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2
          class="title__location rehau-active-red--hover"
          @click="goToLocation(parentSlug)"
        >
          {{ parentLocation.title }}
        </h2>
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2 class="title__location rehau-active-red">
          {{ locationTitle }}
        </h2>
      </span>
    </div>
  </div>
</template>

<script>
import { bus } from "../../main";
export default {
  name: "Title",
  components: {},
  data() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      locationTitle: null,
      stringBreadcrumb: null,
      parentLocation: null,
      parentSlug: null
    };
  },
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  mounted() {
    this.locationTitle = this._vaData.locations.find(
      l => l.id === this._vaData.settings.start_location
    ).title;

    bus.$on("location_reached", id => {
      console.log(this._vaData.locations.find(l => l.id === id));

      this.locationTitle = this._vaData.locations.find(l => l.id === id).title;
    });

    bus.$on("location_left", () => {
      this.locationTitle = false;
    });
  },
  methods: {
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    },
    goHome() {
      bus.$emit("go_to_location", this.settings.start_location);
    }
  },
  created() {
    bus.$on("location_reached", id => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.locationTitle = location.title;
      this.parentLocation = location.parent_location;
      this.parentSlug = location.parent_slug;
      this.stringBreadcrumb = this._vaData.strings.breadcrumb;
    });

    bus.$on("location_left", () => {
      this.locationTitle = null;
      this.parentLocation = null;
      this.parentSlug = null;
      this.stringBreadcrumb = null;
    });
  }
};
</script>

<style lang="scss" scoped>
$animationDuration: 0.5s;
// specify animation duration. Default value: 1s
@import "@/../node_modules/vue2-animate/src/sass/vue2-animate.scss";

.title {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  flex-shrink: 0;
}

.title__main {
  margin: 0;
  font-size: 1.4em;
}
span {
  font-size: 0.9em;
  line-height: 1;
}
.title__location {
  font-weight: 400;
  color: #000;
  overflow: hidden;
  font-size: 0.9em;
  line-height: 1;
  margin: 0px 5px;
}
.breadcrumb {
  margin: 1px 0;
  color: #fff;
  height: 18px;
  align-items: center;
  display: flex;
  margin-left: 0px;
}
.breadcrumb_text {
  display: flex;
  align-items: center;
}
.rehau-active-red {
  color: #dd0060;
}
.button__icon {
  width: 10px;
  height: 10px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}
.button__icon--breadcrumb {
  background-image: url(../../assets/arrow_right.svg);
}
a {
  &:hover {
    text-decoration: none;
    pointer-events: all;
    color: #dd0060;
  }
}
</style>

<script>
import { bus } from "../../main";

// import SlideOut from "./SlideOut.vue";

export default {
  name: "Content",

  props: {},
  methods: {
    show(id, storageName = "products") {
      const data = this._vaData[storageName].find(d => {
        return d.id === id;
      });

      if (!data) {
        this.$notify({
          type: "error",
          title: `Data with ID ${id} not found in ${storageName}!`,
          text: "Perhaps it has been deleted."
        });
        return false;
      }
      bus.$emit("show_slideout", data);
    }
  },
  created() {
    bus.$on(
      "show_content",
      (id, storageName, showAs = "slideout", onClosed) => {
        this.show(id, storageName, showAs);
        this.onClosed = onClosed;
      }
    );
  },
  render() {
    return false;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "index.scss";
</style>

<template lang="html">
  <footer class="footer">
    <div class="navigation">
      <a
        class="footer__button icon-button icon-button--website"
        v-if="strings.website_url"
        :href="strings.website_url"
        target="_blank"
      ></a>
      <a
        class="footer__button icon-button icon-button--phone"
        v-if="strings.phone"
        :href="strings.phone"
        target="_self"
      ></a>
      <a
        class="button"
        v-if="strings.mail_box_url"
        :href="strings.mail_box_url"
        target="_blank"
      >
        <i class="button__icon icon -contact"></i>
      </a>
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          class="footer__button icon-button icon-button--home"
          @click="goHome"
        ></div>
      </transition>
      <transition
        name="fade"
        enter-active-class="fadeInUp"
        leave-active-class="fadeOutDown"
      >
        <div
          class="footer__button icon-button icon-button--back"
          :class="
            parentSlug === null ? 'disable_back--button' : 'enable_back--button'
          "
          @click="goToLocation(parentSlug)"
        ></div>
      </transition>
      <Navigation class="footer__button" />
    </div>
  </footer>
</template>

<script>
import { bus } from "../../main";
import ContactForm from "../ContactForm.vue";
import Navigation from "../Navigation.vue";
export default {
  components: {
    Navigation
  },
  data() {
    return {
      showBack: false,
      locations: this._vaData.locations,
      strings: this._vaData.strings,
      parentSlug: null,
      isHome: true,
      form: this._vaData.strings.forms.find(
        f => f.form_id === "default_contact_form"
      )
    };
  },
  mounted() {
    bus.$on("location_reached", (id, history) => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.showBack = location.show_back_btn && history.length > 0;
      this.isHome = this._vaData.settings.start_location === id;
      this.parentSlug = location.parent_slug;
    });

    bus.$on("location_left", () => {
      this.showBack = false;
      this.parentSlug = null;
    });
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goHome() {
      bus.$emit("go_to_location", this._vaData.settings.start_location);
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    },
    showContactForm() {
      this.form.referer = null;

      this.$modal.show(
        ContactForm,
        {
          form: this.form
        },
        {
          height: "auto",
          adaptive: true,
          scrollable: true
        }
      );
    }
  },
  created() {
    bus.$on("show_contact_form", () => {
      this.showContactForm();
    });

    document.querySelector(".videoapp").addEventListener("click", event => {
      const path = event.path || (event.composedPath && event.composedPath());
      // click wherever to close open link
      if (path.indexOf(document.querySelector(".link--open")) === -1) {
        this.openLink = null;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.footer {
  z-index: 2000;
  background: #000;
  flex-shrink: 0;
  user-select: none;
  display: flex;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;
  color: #fff;
  height: 50px;

  & > div {
    height: 100%;
  }

  .footer__button {
    background-color: $base-body-color;
    border-left: 1px solid rgba(#fff, 0.1);
    border-right: 1px solid rgba(#000, 0.1);

    text-align: center;
    box-sizing: border-box;
    transition: all 0.2s;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

    &:last-child() {
      border-right: 0;
    }
    &:first-child() {
      border-left: 0;
    }

    &:hover {
      color: #fff;
    }
    &:active {
      color: #fff;
    }

    ::v-deep .trigger {
      padding: 0 20px;
    }

    a {
      text-decoration: none;

      &:hover {
        color: #fff;
      }
      &:active {
        color: #fff;
      }
    }
  }

  .external,
  .navigation {
    display: flex;
    flex-direction: row;

    a {
      &:hover {
        text-decoration: none;
        color: #fff;
      }
      &:active {
        text-decoration: none;
        color: #fff;
      }
    }
  }

  .icon-button {
    display: block;
    width: 50px;
    height: 100%;
    background-color: $base-body-color;
  }
  .icon-button--website {
    background-size: auto 48%;
    background-image: url(../../assets/rehau-icon-white.svg);
  }
  .icon-button--phone {
    background-size: auto 58%;
    background-image: url(../../assets/Icon_Smartphone.svg);
  }
  .icon-button--mail {
    background-size: auto 52%;
    background-image: url(../../assets/mail.svg);
  }
  .icon-button--home {
    background-size: auto 48%;
    background-image: url(../../assets/Icon_Haus.svg);
  }
  .icon-button--back {
    background-size: auto 64%;
    background-image: url(../../assets/arrow_left.svg);
  }
  .disable_back--button {
    background-color: #7f7f7f;
    pointer-events: none;
  }
}
a {
  color: #fff;
}
.-contact {
  &:before {
    line-height: 48px !important;
  }
}
</style>

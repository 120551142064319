<template>
  <div class="title">
    <div class="breadcrumb">
      <span>
        <a class="title__location" @click="goHome">{{ stringBreadcrumb }}</a>
      </span>
      <span
        v-if="parentLocation && parentSlug === '01'"
        class="breadcrumb_text"
      >
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2 class="title__location rehau-active-red">
          {{ locationTitle }}
        </h2>
      </span>
      <span
        v-if="parentLocation && parentSlug !== '01'"
        class="breadcrumb_text"
      >
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2
          class="title__location rehau-active-red--hover"
          @click="goToLocation(parentSlug)"
        >
          {{ parentLocation.title }}
        </h2>
        <svg class="button__icon button__icon--breadcrumb"></svg>
        <h2 class="title__location rehau-active-red">
          {{ locationTitle }}
        </h2>
      </span>
    </div>
  </div>
</template>
<script>
import { bus } from "../main";

export default {
  name: "Title",
  data() {
    return {
      settings: this._vaData.settings,
      strings: this._vaData.strings,
      locationTitle: null,
      stringBreadcrumb: null,
      parentLocation: null,
      parentSlug: null
    };
  },
  props: {},
  computed: {
    currentTitle: function() {
      return this.locations;
    }
  },
  methods: {
    goBack: () => {
      bus.$emit("go_back");
    },
    goToLocation: id => {
      bus.$emit("go_to_location", id);
    },
    goHome() {
      bus.$emit("go_to_location", this.settings.start_location);
    }
  },
  created() {
    bus.$on("location_reached", id => {
      const location = this._vaData.locations.find(l => l.id === id);
      this.locationTitle = location.title;
      this.parentLocation = location.parent_location;
      this.parentSlug = location.parent_slug;
      this.stringBreadcrumb = this._vaData.strings.breadcrumb;
    });

    bus.$on("location_left", () => {
      this.locationTitle = null;
      this.parentLocation = null;
      this.parentSlug = null;
      this.stringBreadcrumb = null;
    });
  },
  components: {}
};
</script>
<style lang="scss" scoped>
body {
  &.is-tablet,
  &.is-tablet-ios,
  &.is-android {
    @media (orientation: landscape) {
      .title {
        .breadcrumb {
          .title__location {
            font-size: 1.3em;
          }
        }
      }
    }
  }
}

.title {
  display: flex;
  width: 100%;

  align-items: flex-end;
  gap: 15px;
  justify-content: flex-start;
}

.title__main {
  line-height: 1;
  color: #000000;
  margin: 0;
  font-size: 2em;
  font-weight: 600;
}

.title-border {
  border-left: 1px solid $global-emphasis-color;
  padding-left: 1em;
  float: left;
  @media (max-width: $breakpoint-large) {
    display: none;
  }
}

.title__location {
  font-weight: 500;
  color: #000;
  margin: 0;
  line-height: 1;
  font-size: 1.6em;
  margin-right: 10px;
  margin-left: 10px;
}
a {
  &:hover {
    text-decoration: none;
    pointer-events: all;
    color: #dd0060;
  }
}
.breadcrumb {
  margin: 1px 0;
  color: #fff;
  height: 18px;
  align-items: center;
  display: flex;
  margin-left: 10px;
}
.breadcrumb_text {
  display: flex;

  align-items: center;
}
.button__icon {
  width: 25px;
  height: 25px;
  background-position: center;
  background-size: 50%;
  background-repeat: no-repeat;
}

.button__icon--breadcrumb {
  background-image: url(../assets/arrow_right.svg);
}
.rehau-active-red {
  color: #dd0060;
}
.rehau-active-red--hover {
  &:hover {
    cursor: pointer;
    color: #dd0060;
  }
}
</style>

<template>
  <div>
    <h4 class="mega_trends__title">{{ strings.mega_trends_title }}</h4>
    <div class="mega_trends__links">
      <div class="mega__links">
        <a
          v-for="link in links"
          :key="link.id"
          class=""
          :href="link.website_url"
          :target="'_' + link.target"
          >{{ link.website_text }}</a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "MegaTrends",
  data: function() {
    return {
      strings: this._vaData.strings,
      links: this._vaData.strings.mega_trends
    };
  },
  props: {}
};
</script>
<style lang="scss" scoped>
.mega_trends__title {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: 600;
  color: #000000;
}
.mega_trends__links {
  list-style-type: none;
  padding: 8px 0;
  margin: 0;
  padding: 8px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  .mega__links {
    margin-top: 0;
    padding: 0.6em;

    a {
      color: $base-body-color;
      width: 100%;
      display: inline-block;
      padding-bottom: 0.5em;
      &:hover {
        color: #dd0060 !important;
      }
    }
  }
}
</style>
